import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import Background from './../assets/bg.jpg';
import Eagle from './../assets/eagle.svg';

import * as styles from './../styles/formpage';

const SignUp = () => {
  // Get paramters from url
  const [searchParams, setSearchParams] = useSearchParams();

  const [redirectUrl, setRedirectUrl] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('internal');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [terms, setTerms] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [stale, setStale] = useState(false);

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // Check if there is a redirect url
    if (searchParams.has('redirect')) {
      // Set redirect url
      setRedirectUrl(searchParams.get('redirect') || '');
    } else {
      setStale(true);
    }
  }, [searchParams]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError('');

    // Check if there is a redirect url
    if (redirectUrl === '') {
      
    } else {
      // API request to log in
      setLoading(true);

      const response = await fetch(`${process.env.REACT_APP_AUTH_API_URL}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          password,
          status,
          first_name: firstName,
          last_name: lastName,
          terms
        })
      });

      // Check if response is ok
      if (response.ok) {

        setSuccess(true);

       
      } else {
        // Get error message
        const { error } = await response.json();

        // Set error message
        setError(error);
        setLoading(false);
      }
    }
  };

  return <>
      <styles.LoginWrapper>
        <div className="logo">
          <img src={Eagle} alt="eagle" />
          <h3>St John's College May Ball</h3>
          <p>Sign in or create account</p>
        </div>
        <div className="form">
          { stale && <>
            <div className="header">
              <h2>Stale request</h2>
              <p>You have reached this place incorrectly, this may be because you have bookmarked the login portal directly. Access instead through the site you are trying to access.</p>
              <p><a href="https://mayball.org">Return to the main site</a></p>
            </div>
          </>}
          { success && <>
            <div className="header">
              <h2>User created</h2>
              <p>Please check your email to verify your account, then you can continue to use May Ball services.</p>
              <p><a href="https://mayball.org">Return to the main site</a></p>
            </div>
          </>}
          { (!stale && !success) && <>
            <div className="header">
              <h2>Sign up</h2>
              <p>Already have an account? <Link to={`/login?redirect=${searchParams.get('redirect')}`}>Sign in</Link></p>
            </div>
            <form className="form-content" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="firstname">First name(s)</label>
                <input type="text" id="firstname" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="surname">Surname</label>
                <input type="text" id="surname" value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="password">Account type</label>
                <select value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value="internal">Current St John's College Student</option>
                  <option value="alumni">St John's Alumni</option>
                  <option value="external">Current Cambridge Student from a different college</option>
                  <option value="fellow">St John's Fellow and Affiliates</option>
                  <option value="1888">1888 Club</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="terms">Do you accept our <a href="/assets/2022-sjcmb-terms.pdf" target="_blank">terms and conditions</a></label>
                <input type="checkbox" id="terms" checked={terms} onChange={() => setTerms(!terms)} />
              </div>

              
              { error && <>
                <p className="error">{error}</p>
              </>}

              <button role="submit" className={`${loading ? 'loading' : null}`}>Continue</button> 
            </form>
          </> 
        }
      </div> 
      </styles.LoginWrapper>
      <styles.Background>
        <img src={Background} alt="firework" />
      </styles.Background>
  </>;
}

export default SignUp;
