import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';

import Background from './../assets/bg.jpg';
import Eagle from './../assets/eagle.svg';

import * as styles from './../styles/formpage';

const Login = () => {
  // Get paramters from url
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [redirectUrl, setRedirectUrl] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [stale, setStale] = useState(false);

  useEffect(() => {
    // Check if there is a redirect url
    if (searchParams.has('redirect')) {
      // Set redirect url
      setRedirectUrl(searchParams.get('redirect') || '');
    } else {
      setStale(true);
    }
  }, [searchParams]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    if (loading) return;

    // Check if there is a redirect url
    if (redirectUrl === '') return;
      
    // API request to log in
    setLoading(true);

    const response = await fetch(`${process.env.REACT_APP_AUTH_API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password
      })
    });

    // Check if response is ok
    if (response.ok) {
      // Get token
      const { data } = await response.json();

      // Redirect to redirect url
      window.location.href = `${redirectUrl}?token=${data.token}`;
    } else {
      // Get error message
      const { error, data } = await response.json();

      if (error === "User not verified") {
        // User not verified, we need to redirect to the verification screen

        if (data.status === "alumni") {
          if (data.verificationToken) {
            // Need to collect data for verification
            navigate(`/verify-alumni/data?token=${data.verificationToken}`);
          } else {
            // Redirect to holding page
            navigate(`/verify-alumni`);
          }

        } else if (data.status === "internal" || data.status === "external") {
          // Student so we need to verify through raven
          navigate(`/verify-student?verificationtoken=${data.verificationToken}`);

        } else if (data.status === "fellow") {
          // Manual verification needed so redirect to holding page
          navigate(`/verify-fellow`);

        } else if (data.status === "1888") {
          // Manual verification needed so redirect to holding page
          navigate(`/verify-1888`);
        }
      }

      // Set error message
      setError(error);
      setLoading(false);
      
    }
  };

  const [resendEmailSuccess, setResendEmailSuccess] = useState(false);
  const [resendEmailLoading, setResendEmailLoading] = useState(false);

  const newEmail = async () => {
    if (resendEmailLoading) return;

    setResendEmailLoading(true);
    const response = await fetch(`${process.env.REACT_APP_AUTH_API_URL}/verify/email/resend`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email
      })
    });

    // Check if response is ok
    if (response.ok) {
      setResendEmailSuccess(true);
    }

    setResendEmailLoading(false);
  }

  return <>
      <styles.LoginWrapper>
        <div className="logo">
          <img src={Eagle} alt="eagle" />
          <h3>St John's College May Ball</h3>
          <p>Sign in or create account</p>
        </div>
        <div className="form">
          { stale && <>
            <div className="header">
              <h2>Stale request</h2>
              <p>You have reached this place incorrectly, this may be because you have bookmarked the login portal directly. Access instead through the site you are trying to access.</p>
              <p><a href="https://mayball.org">Return to the main site</a></p>
            </div>
          </>}
          { !stale && <>
            <div className="header">
              <h2>Sign in</h2>
              <p>New user? <Link to={`/signup?redirect=${searchParams.get('redirect')}`}>Create an account</Link></p>
            </div>
            <form className="form-content" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              {
                error === "Email not verified" && <>
                  <p className="error">Email not verified</p>
                  { !resendEmailSuccess && <p className="padding-bottom-1rem"><a onClick={newEmail}>Send new email?</a></p> }
                  { resendEmailSuccess && <p className="padding-bottom-1rem">Email sent - you may need to check your junk email.</p> }
                </>
              }
              { (error && error !== "Email not verified") && <>
                <p className="error">{error}</p>
              </>}

              <button role="submit" className={`${loading ? 'loading' : null}`}>Continue</button>
            </form>

            <div className="divider" />
            
            <p><Link to={`/reset?redirect=${redirectUrl}`}>Reset your password</Link></p>

          </> 
        }
      </div> 
      </styles.LoginWrapper>
      <styles.Background>
        <img src={Background} alt="firework" />
      </styles.Background>
  </>;
}

export default Login;
