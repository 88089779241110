import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import Background from './../assets/bg.jpg';
import Eagle from './../assets/eagle.svg';

import * as styles from './../styles/formpage';

interface VerifyHoldingProps {
  scope: string;
}

const VerifyHolding: React.FC<VerifyHoldingProps> = ({scope}) => {
  return <>
      <styles.LoginWrapper>
        <div className="logo">
          <img src={Eagle} alt="eagle" />
          <h3>St John's College May Ball</h3>
          <p>Sign in or create account</p>
        </div>
        <div className="form">

          <div className="header">
            <h2>Awaiting verification</h2>
            <p>{`Your ${scope} account needs to be verified manually.`}</p>
            <p><a href="https://tickets.mayball.org"><b>Alumni can apply for tickets straight away - log in here.</b></a></p>
            <p>This could take up to a few days - you will receive an email when your account is verified.</p>
            <p>If you have not been succesfully verified after <strong>one week</strong>, please get in contact with us at <a href="mailto:tickets@mayball.org">tickets@mayball.org</a>.</p>
            <p><a href="https://mayball.org">Return to the main site</a></p>
          </div>

      </div> 
      </styles.LoginWrapper>
      <styles.Background>
        <img src={Background} alt="firework" />
      </styles.Background>
  </>;
}

export default VerifyHolding;
