import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  overflow: hidden;
  transform: scale(1.03);

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: blur(5px);
  }
`;

export const LoginWrapper = styled.div`
  width: 60%;
  margin: auto;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;

    .logo {
      margin-bottom: 2rem;
      margin-top: 4rem;
    }
  }

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    img {
      width: 100px;
    }

    h3 {
      margin-top: 10px;
      font-size: 1.5rem;
      font-weight: bold;
      color: #fff;
    } 

    p {
      font-size: 1rem;
      color: #fff;
    }
  }

  .form {
    display: flex;
    flex-direction: column;

    max-width: 40rem;

    background-color: ${props => props.theme.colors.background};

    padding: 3rem;
    border-radius: 5px;

    p {
      font-size: ${props => props.theme.fontSizes.small};
      font-family: ${props => props.theme.fonts.body};
      font-weight: 400;
      color: ${props => props.theme.colors.text};

      a {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;

        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    
    .header {
      margin-bottom: 2rem;

      & > * {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      h2 {
        font-size: ${props => props.theme.fontSizes.large};
        font-family: ${props => props.theme.fonts.body};
        font-weight: 700;
        color: ${props => props.theme.colors.text};
      }
    }

    .divider {
      margin: 2rem 0;
      border-bottom: 1px solid #ccc;
    }

    .form-content {
      .form-group {
        margin-bottom: 2rem;

        label {
          font-size: ${props => props.theme.fontSizes.tiny};
          font-family: ${props => props.theme.fonts.body};
          font-weight: 400;
          color: ${props => props.theme.colors.muted};

          display: block;
          margin-bottom: 0.5rem;

        }

        input {
          font-size: ${props => props.theme.fontSizes.small};
          font-family: ${props => props.theme.fonts.body};
          font-weight: 400;
          color: ${props => props.theme.colors.text};
          
          outline: none;

          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 1px solid #aaa;
          width: 100%;

          &[type=checkbox] {
            width: auto;
          }
        }

        select {
          font-size: ${props => props.theme.fontSizes.small};
          font-family: ${props => props.theme.fonts.body};
          font-weight: 400;
          color: ${props => props.theme.colors.text};
          background-color: ${props => props.theme.colors.background};

          outline: none;

          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 1px solid #aaa;
          width: 100%;

          option {
            font-size: ${props => props.theme.fontSizes.small};
            font-family: ${props => props.theme.fonts.body};
            font-weight: 400;
            color: ${props => props.theme.colors.text};
            background-color: ${props => props.theme.colors.background};

            outline: none;

            border-left: none;
            border-right: none;
            border-top: none;
            border-bottom: 1px solid #aaa;
            width: 100%;
          }
        }
      }

      .error {
        font-size: ${props => props.theme.fontSizes.small};
        font-family: ${props => props.theme.fonts.body};
        font-weight: 700;
        color: ${props => props.theme.colors.error};

        margin-bottom: 1rem;
      }

      button {
        font-size: ${props => props.theme.fontSizes.small};
        font-family: ${props => props.theme.fonts.body};
        font-weight: 400;
        color: ${props => props.theme.colors.primary};
        background-color: ${props => props.theme.colors.background};

        border: 1px solid ${props => props.theme.colors.primary};
        border-radius: 5px;
        padding: 0.5rem 1rem;

        outline: none;

        cursor: pointer;

        &:hover {
          background-color: ${props => props.theme.colors.primary};
          color: ${props => props.theme.colors.background};
        }
      }
    }
  }
`;
