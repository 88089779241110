import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useParams } from 'react-router-dom';

import Background from './../assets/bg.jpg';
import Eagle from './../assets/eagle.svg';

import * as styles from './../styles/formpage';

const ResetPasswordVerify = () => {
  // Get paramters from url
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState('');
  
  const [redirectUrl, setRedirectUrl] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [stale, setStale] = useState(false);

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // Check if there is a redirect url and token
    if (searchParams.has('redirect') && searchParams.has('token')) {
      // Set redirect url
      setRedirectUrl(searchParams.get('redirect')!);
      setToken(searchParams.get('token')!)

      setSearchParams({});
    } else {
      setStale(true);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    if (loading) return;

    // API request to log in
    setLoading(true);

    const response = await fetch(`${process.env.REACT_APP_AUTH_API_URL}/users/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        password
      })
    });

    // Check if response is ok
    if (response.ok) {
      // Get token
      setSuccess(true);
    } else {
      const { error } = await response.json();
      setError(error);
    }

    setLoading(false);    
  };

  return <>
      <styles.LoginWrapper>
        <div className="logo">
          <img src={Eagle} alt="eagle" />
          <h3>St John's College May Ball</h3>
          <p>Sign in or create account</p>
        </div>
        <div className="form">
          { stale && <>
            <div className="header">
              <h2>Stale request</h2>
              <p>You have reached this place incorrectly, this may be because you have bookmarked the login portal directly. Access instead through the site you are trying to access.</p>
              <p><a href="https://mayball.org">Return to the main site</a></p>
            </div>
          </>}

          { (!stale && !success) && <>

          <div className="header">
            <h2>Reset password</h2>
            <p>Enter your new password.</p>
          </div>
          <form className="form-content" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="password">New password</label>
              <input type="password" id="email" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            
            { error && <>
              <p className="error">{error}</p>
            </>}

            <button role="submit" disabled={loading} className={`${loading ? 'loading' : ''}`}>Continue</button> 
          </form>
          </>}

          { success && <>
            <div className="header">
              <h2>Password reset</h2>
              <p>Your password has successfully been updated.</p>
              <p><Link to={`/login?redirect=${redirectUrl}`}>Return to login</Link></p>
            </div>
          </>}
      </div> 
      </styles.LoginWrapper>
      <styles.Background>
        <img src={Background} alt="firework" />
      </styles.Background>
  </>;
}

export default ResetPasswordVerify;
