import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';

import Background from './../assets/bg.jpg';
import Eagle from './../assets/eagle.svg';

import * as styles from './../styles/formpage';

const VerifyAlumni = () => {
  // Get paramters from url
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [verificationToken, setVerificationToken] = useState('');

  const [matricYear, setMatricYear] = useState(''); // Year of matriculation
  const [gradYear, setGradYear] = useState(''); // Year of graduation

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [stale, setStale] = useState(false);

  useEffect(() => {
    // Check if there is a redirect url
    if (searchParams.has('token')) {
      // Set redirect url
      setVerificationToken(searchParams.get('token') || '');

      setSearchParams({});
    } else {
      setStale(true);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    if (loading) return;

    // Check if there is a verification token
    if (verificationToken === '') return;
      
    // API request to log in
    setLoading(true);

    const response = await fetch(`${process.env.REACT_APP_AUTH_API_URL}/verify/alumni/data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: verificationToken,
        matriculation: matricYear,
        graduation: gradYear
      })
    });

    // Check if response is ok
    if (response.ok) {
      // Successfully added data, redirect to holding page
      navigate('/verify-alumni');    

    } else {
      // Get error message
      const { error } = await response.json();

      // Set error message
      setError(error);
      setLoading(false);
      
    }
  };

  return <>
      <styles.LoginWrapper>
        <div className="logo">
          <img src={Eagle} alt="eagle" />
          <h3>St John's College May Ball</h3>
          <p>Sign in or create account</p>
        </div>
        <div className="form">
          { stale && <>
            <div className="header">
              <h2>Stale request</h2>
              <p>You have reached this place incorrectly, this may be because you have bookmarked the login portal directly. Access instead through the site you are trying to access.</p>
              <p><a href="https://mayball.org">Return to the main site</a></p>
            </div>
          </>}
          { !stale && <>
            <div className="header">
              <h2>Alumni verification</h2>
              <p>In order to verify your account, we need to collect your matriculation, and graduation years.</p>
            </div>
            <form className="form-content" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="matriculation">Matriculation Year</label>
                <input type="text" id="matriculation" value={matricYear} onChange={(e) => setMatricYear(e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="graduation">Graduation Year</label>
                <input type="text" id="graduation" value={gradYear} onChange={(e) => setGradYear(e.target.value)} />
              </div>
              
              { error && <>
                <p className="error">{error}</p>
              </>}

              <button role="submit" className={`${loading ? 'loading' : null}`}>Continue</button>
            </form>
          </> 
        }
      </div> 
      </styles.LoginWrapper>
      <styles.Background>
        <img src={Background} alt="firework" />
      </styles.Background>
  </>;
}

export default VerifyAlumni;
