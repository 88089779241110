import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

// Pages
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordVerify from './pages/ResetPasswordVerify';
import SignUp from './pages/SignUp';
import VerifyEmail from './pages/VerifyEmail';

import VerifyAlumni from './pages/VerifyAlumni';
import VerifyRaven from './pages/VerifyRaven';
import VerifyRavenCallback from './pages/VerifyRavenCallback';
import VerifyHolding from './pages/VerifyHolding';


// Styles
import { Theme } from './styles/Theme';
import { GlobalStyle } from './styles/GlobalStyle';

const App = () => {
  return <>
  <ThemeProvider theme={Theme}>
    <GlobalStyle />
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/reset/callback" element={<ResetPasswordVerify />} />
        <Route path="/signup" element={<SignUp />} />

        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/verify-email/success" element={<VerifyEmail success />} />
        <Route path="/verify-email/error" element={<VerifyEmail error />} />

        <Route path="verify-alumni/data" element={<VerifyAlumni />} />
        <Route path="/verify-alumni" element={<VerifyHolding scope={'alumni'} />} />
        <Route path="/verify-fellow" element={<VerifyHolding scope={`St John's fellow`} />} />
        <Route path="/verify-1888" element={<VerifyHolding scope={'1888 Club'} />} />

        <Route path="/verify-student" element={<VerifyRaven />} />
        <Route path="/verify-student/callback" element={<VerifyRavenCallback />} />
      </Routes>
    </Router>
  </ThemeProvider>
  </>;
}

export default App;
