import React, { useState, useEffect } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';

import Background from './../assets/bg.jpg';
import Eagle from './../assets/eagle.svg';

import * as styles from './../styles/formpage';

const VerifyRaven = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [verificationToken, setVerificationToken] = useState('');
  const [stale, setStale] = useState(false);


  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // If we have a token in the URL, add to context
    if (searchParams.has('verificationtoken')) {
      const token = searchParams.get('verificationtoken')!;
      setVerificationToken(token);
      
      // Remove token from URL
      setSearchParams({});
    } else {
      setStale(true);
    }
  }, []);

  const verify = async () => {
    // Redirect to auth raven
    window.location.href = `${process.env.REACT_APP_AUTH_RAVEN_URL}/login?redirect=${process.env.REACT_APP_AUTH_FRONTEND_URL}/verify-student/callback?verificationtoken=${verificationToken}`;
  }

  return <>
    <styles.LoginWrapper>
        <div className="logo">
          <img src={Eagle} alt="eagle" />
          <h3>St John's College May Ball</h3>
          <p>Sign in or create account</p>
        </div>
        <div className="form">
          { stale && <>
            <div className="header">
              <h2>Stale request</h2>
              <p>You have reached this place incorrectly, this may be because you have bookmarked the login portal directly. Access instead through the site you are trying to access.</p>
              <p><a href="https://mayball.org">Return to the main site</a></p>
            </div>
          </>}
          { !stale && <>
            <div className="header">
              <h2>Verify with Raven</h2>
              <p>In order to verify your student and college status, you will need to verify through Raven.</p>
            </div>
            <div className="form-content">
              <button onClick={verify}>Verify through Raven</button>
            </div>
          </>}
      </div> 
      </styles.LoginWrapper>
      <styles.Background>
        <img src={Background} alt="firework" />
      </styles.Background>
  </>;
}

export default VerifyRaven;