import { createGlobalStyle } from 'styled-components';

import { Theme } from './Theme';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0px;
    box-sizing: border-box;
  }
  body {
    font-family: ${Theme.fonts.body};
  }
  a {
    color: inherit;
  }
  b {
    font-weight: 700;
  }
  
  h1,h2,h3,h4,h5,h6 {
    font-family: ${Theme.fonts.heading};
  }

  button.loading {
    cursor: wait !important;
    
    &:hover {
      background-color: inherit !important;
      color: inherit !important;
    }
  }
  .padding-bottom-1rem {
    padding-bottom: 1rem;
  }
`;