import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import Background from './../assets/bg.jpg';
import Eagle from './../assets/eagle.svg';

import * as styles from './../styles/formpage';

interface VerifyEmailProps {
  success?: boolean;
  error?: boolean;
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({success = false, error = false, ...props}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [token, setToken] = useState('');
  const [stale, setStale] = useState(false);
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const [resendEmailSuccess, setResendEmailSuccess] = useState(false);
  const [resendEmailError, setResendEmailError] = useState(false);

  // Get token from URL
  useEffect(() => {
    // Check if there is a redirect url
    if (searchParams.has('token')) {
      // Set redirect url
      setToken(searchParams.get('token') || '');

      setSearchParams({});
    } else {
      setStale(true);
    }
  }, []);

  const verify = async (token: string) => {
    setToken(token);

    // Send token to server to verify
    const response = await fetch(`${process.env.REACT_APP_AUTH_API_URL}/verify/email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    });
    const data = await response.json();

    if (response.ok) {
      // Successfully verified
      navigate('/verify-email/success', { replace: true });

    } else {
      if (data.error === "User already verified") {
        navigate('/verify-email/success', { replace: true });
      } else {
        if (data.error === "Token expired") {
          // Extract email
          let base64Data = token.split('.')[1];
          let userData = JSON.parse(Buffer.from(base64Data, 'base64').toString());

          setEmail(userData.email);
        }
        navigate('/verify-email/error', { replace: true });
      }
    }
  }

  useEffect(() => {
    if (token) {
      verify(token);
    }
  }, [token]);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (loading) return;

    // API request to send email
    setLoading(true);

    const response = await fetch(`${process.env.REACT_APP_AUTH_API_URL}/verify/email/resend`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email
      })
    });

    // Check if response is ok
    if (response.ok) {
      setResendEmailSuccess(true);
    } else {
      const { error } = await response.json();
      setResendEmailError(error);
    }

    setLoading(false);
  }

  return <>
    <styles.LoginWrapper>
      <div className="logo">
        <img src={Eagle} alt="eagle" />
        <h3>St John's College May Ball</h3>
        <p>Sign in or create account</p>
      </div>
      <div className="form">
        { stale && <>
            <div className="header">
              <h2>Stale request</h2>
              <p>You have reached this place incorrectly, this may be because you have bookmarked the login portal directly. Access instead through the site you are trying to access.</p>
              <p><a href="https://mayball.org">Return to the main site</a></p>
            </div>
          </>}
        { success && <>
          <div className="header">
            <h2>Email verified</h2>
            <p>Your email has been verified successfully. You can now close this page.</p>
            <p><a href="https://mayball.org">Return to main site</a></p>
          </div>
        </>}
        { (error && !resendEmailSuccess) && <>
          <div className="header">
            <h2>An error has occured</h2>
            <p>An error has occured verifying your password.</p>
            <p>Resend verification email?</p>
            
            { resendEmailError && <>
              <p className="error">{resendEmailError}</p>
            </>}
            <div className="form-content">
              <div className="form-group">
                <button onClick={handleClick} className={`${loading ? 'loading' : null}`}>Send</button>
              </div>
            </div>
          </div>
        </>}
        { resendEmailSuccess && <>
            <div className="header">
              <h2>Verification email sent</h2>
              <p>Check your email - instructions for how to verify your email have been sent.</p>
              <p>You can now close this page</p>
            </div>
        </>}
        { (!success && !error) && <>
          <div className="header">
            <h2>Verifying your account</h2>
            <p>Please wait while we verify your account</p>
          </div>
        </>}
      </div> 
    </styles.LoginWrapper>
    <styles.Background>
      <img src={Background} alt="firework" />
    </styles.Background>
  </>;
}

export default VerifyEmail;
