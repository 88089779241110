import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import Background from './../assets/bg.jpg';
import Eagle from './../assets/eagle.svg';

import * as styles from './../styles/formpage';

const ResetPassword = () => {
  // Get paramters from url
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  
  const [redirectUrl, setRedirectUrl] = useState('');
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [stale, setStale] = useState(false);

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // Check if there is a redirect url
    if (searchParams.has('redirect')) {
      // Set redirect url
      setRedirectUrl(searchParams.get('redirect') || '');
    } else {
      setStale(true);
    }
  }, [searchParams]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    if (loading) return;

    // API request to log in
    setLoading(true);

    const response = await fetch(`${process.env.REACT_APP_AUTH_API_URL}/users/reset/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        redirect_url: redirectUrl
      })
    });

    // Check if response is ok
    if (response.ok) {
      // Get token
      setSuccess(true);
    } else {
      const { error } = await response.json();
      setError(error);
    }

    setLoading(false);    
  };

  return <>
      <styles.LoginWrapper>
        <div className="logo">
          <img src={Eagle} alt="eagle" />
          <h3>St John's College May Ball</h3>
          <p>Sign in or create account</p>
        </div>
        <div className="form">
          { stale && <>
            <div className="header">
              <h2>Stale request</h2>
              <p>You have reached this place incorrectly, this may be because you have bookmarked the login portal directly. Access instead through the site you are trying to access.</p>
              <p><a href="https://mayball.org">Return to the main site</a></p>
            </div>
          </>}

          { (!stale && !success) && <>

          <div className="header">
            <h2>Reset password</h2>
            <p><Link to={`/login?redirect=${redirectUrl}`}>Return to sign in</Link></p>
            <p>Enter your email address to receive details on how to reset your password.</p>
          </div>
          <form className="form-content" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            
            { error && <>
              <p className="error">{error}</p>
            </>}

            <button role="submit" disabled={loading} className={`${loading ? 'loading' : ''}`}>Continue</button> 
          </form>
          </>}

          { success && <>
            <div className="header">
              <h2>Password reset email sent</h2>
              <p>Check your email - instructions for how to reset your password have been sent.</p>
              <p>You can now close this page</p>
            </div>
          </>}
      </div> 
      </styles.LoginWrapper>
      <styles.Background>
        <img src={Background} alt="firework" />
      </styles.Background>
  </>;
}

export default ResetPassword;
