import React, { useState, useEffect } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';

import Background from './../assets/bg.jpg';
import Eagle from './../assets/eagle.svg';

import * as styles from './../styles/formpage';

interface UserData {
  crsid: string;
  college: string;
}

const VerifyRavenCallback = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [verificationToken, setVerificationToken] = useState(''); // Verification token
  const [ravenToken, setRavenToken] = useState(''); // Raven token
  const [stale, setStale] = useState(false);
  const [error, setError] = useState('');
  const [userData, setUserData] = useState({} as UserData)

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);


  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // If we have a token in the URL, add to context
    if (searchParams.has('verificationtoken') && searchParams.has('token')) {
      const verificationtoken = searchParams.get('verificationtoken')!;
      const ravenTokenURL = searchParams.get('token')!;
      setVerificationToken(verificationtoken);
      setRavenToken(ravenTokenURL);
      
      // Remove token from URL
      setSearchParams({});

      // Verify token
      verifyTokens(verificationtoken, ravenTokenURL);
     
    } else {
      setStale(true);
    }
  }, []);

  const verifyTokens = async (vt: string, rt: string) => {
    console.log({
      vt,
      rt
    })
    const response = await fetch(`${process.env.REACT_APP_AUTH_API_URL}/verify/student`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          verificationToken: vt,
          ravenToken: rt
        })
    });

    if (response.ok) {
      // Returned information about student from lookup to confirm
      const { data } = await response.json();

      setUserData(data.user);
    } else {
      const { error } = await response.json();
      setError(error);
    }

    setLoading(false);
  }

  const verifyUserData = async () => {
    const response = await fetch(`${process.env.REACT_APP_AUTH_API_URL}/verify/student/data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          verificationToken,
          ravenToken
        })
    });

    if (response.ok) {
      // Everything verified

      setSuccess(true);
    } else {
      const { error } = await response.json();
      setError(error);
    }
  }

  return <>
    <styles.LoginWrapper>
        <div className="logo">
          <img src={Eagle} alt="eagle" />
          <h3>St John's College May Ball</h3>
          <p>Sign in or create account</p>
        </div>
        <div className="form">
          { (stale && !error && !success) && <>
            <div className="header">
              <h2>Stale request</h2>
              <p>You have reached this place incorrectly, this may be because you have bookmarked the login portal directly. Access instead through the site you are trying to access.</p>
              <p><a href="https://mayball.org">Return to the main site</a></p>
            </div>
          </>}
          { (!stale && !userData.crsid && !error) && <>
            <div className="header">
              <h2>Verify with Raven</h2>
              <p>In order to verify your student and college status, you will need to verify through Raven.</p>
            </div>
            <div className="form-content">
              <button >Verify through Raven</button>
            </div>
          </>}
          {
            (!stale && userData.crsid && !error && !success) && <>
              <div className="header">
                <h2>Please check the following information</h2>
                <p>If the following information is correct, please click verify and your account will be fully verified.</p>
                <p>If it is not correct, please email <a href="mailto:tickets@mayball.org">tickets@mayball.org</a>.</p>
              </div>
              <div className="form-content">
                <div className="form-group">
                  <p><strong>Crsid:</strong> {userData.crsid}</p>
                  </div>
                <div className="form-group">
                  <p><strong>College:</strong> {userData.college}</p>
                </div>

                <button onClick={verifyUserData}>Verify</button>
              </div>
          </>}
          
          { error && <>
            <div className="header">
                <h2>Something went wrong</h2>
                <p className="error">{error}</p>
                <p>Please try again, or contact <a href="mailto:tickets@mayball.org">tickets@mayball.org</a></p>
            </div>
          </>}

          { (success && !error) && <>
            <div className="header">
                <h2>Successfully veried student status</h2>
                <p>Your account has been verified. You may now sign in.</p>
                <p><a href="https://mayball.org">Return to the main site</a></p>
            </div>
          </>}

      </div> 
      </styles.LoginWrapper>
      <styles.Background>
        <img src={Background} alt="firework" />
      </styles.Background>
  </>;
}

export default VerifyRavenCallback;